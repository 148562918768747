import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Siden blev ikke fundet" />
    <h1>Siden blev desværre ikke fundet</h1>
    <p>Du har ramt en side, som der ikke eksisterer... </p>
  </Layout>
)

export default NotFoundPage
